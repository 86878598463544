<template>
  <div class="new-center">
    <div class="content-left">
      <div style="text-align: left;">
        <div class="left-title">联系我们</div>
        <div class="left-sub-title">CONTACT US</div>
        <div class="tab-menu">
          <div v-for="(item,i) in tabMenu" :key="i" class="menu-item" style="color: #003f99;">
            <i class="el-icon-caret-right"></i>
            <span >{{ item.name }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="content-right">
        <div class="content-title">
          <span class="title-line">{{ currentTab.name }}</span>
        </div>
        <div class="content-info">
          <div class="contact-us">
            <div class="contact-us-info">
              <div>
                <div class="contact-title"><img src="@/assets/page6/phone.png" alt=""><span>赣榆城发热线</span></div>
                <div class="contact-num">{{ commonData.phone }}</div>
              </div>
              <div>
                <div class="contact-title"><img src="@/assets/page6/mail.png" alt=""><span>赣榆城发邮箱</span></div>
                <div class="contact-num">{{ commonData.email }}</div>
              </div>
            </div>
            <div id="baidu_map" style="height: 48vh;margin-top: 5vh;"></div>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import { getCommonInfo} from "../../../js/api/index";
export default {
  data() {
    return {
      commonData:'',
      tabMenu:[
        {name:'联系我们',level:1}
      ],
      currentTab:{
        name:'联系我们',
        num:1
      }
    };
  },
  computed: {},
  methods: {
    initmap(){
      // 百度地图API功能
      var map = new BMap.Map("baidu_map");    // 创建Map实例
      var point = new BMap.Point(this.commonData.longitude,this.commonData.latitude);
      map.setCurrentCity("连云港");          // 设置地图显示的城市 此项是必须设置的
      map.enableScrollWheelZoom(true);     //开启鼠标滚轮缩放
      var marker = new BMap.Marker(point);  // 创建标注
      map.addOverlay(marker);              // 将标注添加到地图中
      var opts = {
          width: 400,     // 信息窗口宽度
          height: 30,     // 信息窗口高度
          title: "", // 信息窗口标题
      }
      // var sContent = '<div style="width:400px;font-size:0.9vw;border-radius:10px">'+this.commonData.address+'</div>';
      // var infoWindow = new BMap.InfoWindow(sContent);
      var infoWindow = new BMap.InfoWindow(this.commonData.address, opts);  // 创建信息窗口对象    
      map.openInfoWindow(infoWindow, point);
      setTimeout(function(){
        map.centerAndZoom(point, 20);// 初始化地图,设置中心点坐标和地图级别
      },100)
    },
    //初始化常量信息
    initCommonList(){
      getCommonInfo().then((res) => {
        let list = res.data.list;
        this.commonData = list[0];
        this.initmap();
      });
    },
  },
  mounted() {
   this.initCommonList();
  }
};
</script>

<style lang="scss" scoped>
.new-center{
  max-width: 1280px;
  margin: 0 auto;
  display: flex;
}

.content-left{
  width: 28.6%;
  padding-left: 30px;
  padding-right: 0;
  padding-top: 40px;
  padding-bottom: 30vh;
  background: #f7f7f7;
  .left-title{
    font-family: 微软雅黑;
    font-size: 22px;
    font-weight: bold;
    font-style: normal;
    color: #003f99;
    padding: 0 0 5px 0;
    display: block;
    padding: 0 0 5px 0;
  }
  .left-sub-title{
    font-family: arial;
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    color: #bababa;
    display: block;
  }
  .tab-menu{
    width: 75%;
    margin-top: 10px;
    .sub-menu{
      font-size: 14px;
      padding-left: 30px;
    }
  }
  .menu-item{
    cursor: pointer;
    padding: 15px 10px;
    font-size: 16px;
    color: #666666;
  }
  .menu-item:not(:last-child){
    border-bottom: 1px solid gainsboro;
  }
  .menu-item:hover{
    color: #003f99;
  }
}
.content-right{
  width: 71.4%;
  padding-left: 30px;
  padding-right: 0;
  padding-top: 40px;
  .content-title{
    text-align: left;
    border-bottom: 1px solid gainsboro ;
    padding: 5px 0 5px 15px;
    color: #003f99;
    position: relative;
  }
  .title-line::before{
    position: absolute;
    content: '';
    height: 20px;
    width: 4px;
    background: #003f99;
    left:0
  }
}
.contact-us{
  margin-bottom: 20px;
  margin-top: 20px;
  .contact-us-info{
    display: flex;
    text-align: left;
    >div{
      width: 50%;
      padding: 1vh 1vw;
    }
    img{
      width: 20px;
      height: 20px;
      margin-right: 5px;
    }
    .contact-title{
      font-size: 14px;
      font-weight: bold;
      color: #393939;
      display: flex;
      align-items: center;
    }
    .contact-num{
      font-size: 16px;
      font-weight: bold;
      color: #1F4692;
    }
  }
}
</style>